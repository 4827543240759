<template>
  <section class="container">
    <div class="row justify-content-end">
      <div class="col-auto">
        <BaseActionButton type="button" v-on:on-click="setEditable">
          <font-awesome-icon :icon="['far', 'edit']" class="icon" />
        </BaseActionButton>
      </div>
    </div>
    <div>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="signup-emailForm">
          <Supplier v-model="supplier" :disabled="disabled"></Supplier>
          <BaseActionButton
            class="btn-update"
            v-bind:clicked="clicked"
            v-bind:disabled="clicked"
            type="submit"
          >
            {{ $t("global.update") }}
          </BaseActionButton>
        </b-form-group>
      </b-form>
    </div>
  </section>
</template>

<script>
import Supplier from "@/modules/general/supplier/components/Supplier.vue";

export default {
  name: "SupplierEdit",
  components: { Supplier },
  data() {
    return {
      clicked: false,
      disabled: true,
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
  },
  methods: {
    setEditable() {
      this.disabled = !this.disabled;
    },
    async onSubmit() {
      this.clicked = true;
      var payload = this.supplier;
      var id = this.id;
      try {
        var result = await this.$store.dispatch("supplierVuex/update", {
          payload,
          id,
        });
        this.clicked = false;
        this.toast(
          "Success",
          "Pensionselskabet er nu opdateret", true
        );
      } catch (error) {
        this.clicked = false;
        this.toast(
          error.response.data.code,
          error.response.data.message, false
        );
      }
    },
  },
  computed: {
    id() {
      return this.$route.params.supplierId;
    },
    supplier() {
      return this.$store.getters["supplierVuex/supplier"];
    },
  },
};
</script>

<style lang="scss">
</style>